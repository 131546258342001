import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ADMIN_SUBMISSIONS } from '@shared/constants/queryKeys';
import { toastError, toastSuccess } from '@shared/utils';

import { productSubmissionsApi } from '../productSubmissionsApi';

const useApproveSubmission = (id: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => productSubmissionsApi.client.approveSubmission(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMISSIONS] });
      onSuccess && onSuccess();
      toastSuccess('Product approved successfully.');
    },
    onError: (e) => {
      toastError(e.message);
    },
  });
};

export default useApproveSubmission;
