import { useMemo } from 'react';

import { UserGetProductsDetailData } from '@interfaces';
import { useQuery } from '@tanstack/react-query';

import { ADMIN_SUBMISSION_DETAILS } from '@shared/constants/queryKeys';

import { productSubmissionsApi } from '../productSubmissionsApi';

const useGetAdminSubmissionDetails = (submissionId: number) => {
  const { data } = useQuery({
    queryKey: [ADMIN_SUBMISSION_DETAILS, submissionId],
    queryFn: () =>
      productSubmissionsApi.client.getAdminSubmissionDetails(submissionId),
    enabled: !!submissionId,
  });

  return {
    data: useMemo(
      () =>
        ({
          ...data?.data.product,
          ...data?.data.productSubmissionHistory,
          tags: data?.data.productSubmissionHistory?.tags.map((tag) => ({
            name: tag,
          })),
          categories: data?.data.productSubmissionHistory?.categories?.map(
            (category) => ({
              name: category,
            })
          ),
          createdAt: data?.data.createdAt,
          status: data?.data.status,
          slotAvailable: data?.data.slotAvailable,
        }) as UserGetProductsDetailData,
      [data?.data]
    ),
  };
};

export default useGetAdminSubmissionDetails;
