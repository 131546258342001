import { useRef, useState } from 'react';

import useRejectSubmission from '@api/admin/product-submissions/mutations/useRejectSubmission';

import ReasonItem from '@components/ReasonItem';
import Button, { BUTTON_VARIANT } from '@components/button';

import { REASON_OPTIONS } from '@shared/constants';
import { useProductActionsContext } from '@shared/contexts/ProductActionsContext';
import { E_REASON_OPTIONS } from '@shared/enums';

interface IProps {
  closeModal: () => void;
  submissionId: string;
}

const ReasonSelect = ({ closeModal, submissionId }: IProps) => {
  const { setProductToApprove } = useProductActionsContext();
  const { mutateAsync, isPending } = useRejectSubmission(submissionId, () => {
    closeModal();
    setProductToApprove(undefined);
  });
  const [selectedItems, setSelectedItems] = useState<E_REASON_OPTIONS[]>([
    E_REASON_OPTIONS.VIOLENT_OR_REPULSIVE_CONTENT,
  ]);
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const textInputRef = useRef<HTMLTextAreaElement>(null);

  const isOptionSelected = (value: E_REASON_OPTIONS) =>
    selectedItems.includes(value);

  const handleSelectChange = (value: E_REASON_OPTIONS) => {
    if (isOptionSelected(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const handleTextChange = (value: string) => {
    setText(value);

    if (errorMessage) setErrorMessage('');
  };

  const handleReject = () => {
    if (isOptionSelected(E_REASON_OPTIONS.OTHER)) {
      if (!text) {
        textInputRef.current?.focus();
        return setErrorMessage('Please provide your other reason');
      }
    }

    mutateAsync({
      rejectReason: selectedItems,
      rejectDescription: text,
    });
  };

  return (
    <div className="bg-gray-800">
      {REASON_OPTIONS.map((option) => (
        <ReasonItem
          isSelected={isOptionSelected(option.value)}
          option={option}
          key={option.value}
          setSelectedItem={() => handleSelectChange(option.value)}
          text={text}
          setText={handleTextChange}
          errorMessage={errorMessage}
          ref={textInputRef}
        />
      ))}
      <hr className="my-6 border-neutral700" />
      <div className="flex justify-between">
        <Button
          onClick={closeModal}
          variant={BUTTON_VARIANT.SECONDARY}
          className="w-fit"
        >
          Cancel
        </Button>
        <Button
          onClick={handleReject}
          disabled={isPending || !selectedItems || !!errorMessage}
          className="w-fit"
        >
          Reject product
        </Button>
      </div>
    </div>
  );
};

export default ReasonSelect;
