import { IProduct, UserGetProductsDetailData } from '@interfaces';

import { formatDate, formatTimeToHM } from '@features/utils';

export const getVisibilityModalGridData = (
  product: UserGetProductsDetailData | IProduct
) => {
  return [
    {
      label: 'Product',
      value: product.name || 'Unnamed Product',
    },
    {
      label: 'Publisher',
      value: product.publisher?.user?.name || 'N/A',
    },
    {
      label: 'Date created',
      value: product.createdAt ? (
        <div className="flex items-center gap-2">
          {formatTimeToHM(product.createdAt)}
          <div className="h-[3px] w-[3px] rounded-full bg-neutral500" />
          {formatDate(product.createdAt)}
        </div>
      ) : (
        'N/A'
      ),
    },
  ];
};
