import { usePathname } from 'next/navigation';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { twMerge } from 'tailwind-merge';

import DividerFullWidth from '@components/dividerFullWidth';
import Logo from '@components/logo';
import RemoveBoxedIcon from '@components/removeBoxedIcon';

import { MenuIcon } from '@shared/assets';
import { HOME } from '@shared/utils';

interface IProps {
  children: ReactNode;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  iconWrapperClassName?: string;
  hideLogo?: boolean;
  closeIconClassName?: string;
  setIsSubMenuOpen?: Dispatch<SetStateAction<boolean>>;
  // Used to add responsivity to the menu.
  // This is needed because we started hiding the sidebar on different sizes rather than what was originally planned.
  responsiveClassName?: string;
  childrenContainerClassName?: string;
}

const MobileMenu = ({
  setIsOpen,
  children,
  isOpen,
  iconWrapperClassName,
  hideLogo,
  closeIconClassName,
  setIsSubMenuOpen,
  responsiveClassName,
  childrenContainerClassName,
}: IProps) => {
  const pathname = usePathname();
  return (
    <RemoveScroll
      enabled={isOpen}
      className={twMerge('h-full', responsiveClassName || 'md:hidden')}
    >
      <div className={twMerge('h-full', responsiveClassName || 'md:hidden')}>
        <button
          className={twMerge('nav-bar-icon border-r-0', iconWrapperClassName)}
          onClick={() => setIsOpen(true)}
        >
          <MenuIcon />
        </button>
        <div
          className={twMerge(
            'fixed top-0 z-99 flex h-dvh w-screen flex-col bg-neutral900 transition-all duration-300',
            isOpen ? 'left-0' : 'left-full'
          )}
        >
          <>
            <div
              className={twMerge(
                'flex h-16 shrink-0 items-center justify-between',
                hideLogo && 'justify-end'
              )}
            >
              {!hideLogo && (
                <Logo
                  isOnlyFullLogo
                  isHomepage={pathname === HOME}
                  className="w-40 max-xs:w-28"
                />
              )}
              <RemoveBoxedIcon
                onClick={() => {
                  setIsOpen(false);
                  setIsSubMenuOpen?.(false);
                }}
                className="border-b border-l border-neutral700"
                iconClassName={twMerge('scale-125', closeIconClassName)}
              />
            </div>
            <DividerFullWidth className="shrink-0" />
          </>
          <div className={childrenContainerClassName ?? ''}>{children}</div>
        </div>
      </div>
    </RemoveScroll>
  );
};

export default MobileMenu;
