'use client';

import { useState } from 'react';

import { IProductWithSubmissionId } from '@interfaces/admin/submissions';

import useGetAdminSubmissionDetails from '@api/admin/product-submissions/queries/useGetAdminSubmissionDetails';

import NavigationActions from '@components/NavigationActions/NavigationActions';

import { useProductActionsContext } from '@shared/contexts/ProductActionsContext';
import { ADMIN } from '@shared/utils';

import ApproveProductSubmissionsModal from '../ApproveModal/ApproveProductSubmissionsModal';
import RejectModal from '../RejectModal/RejectModal';

const SubmissionNavActions = ({
  product,
}: {
  product: IProductWithSubmissionId;
}) => {
  const { setProductToApprove } = useProductActionsContext();
  const [approve, setApprove] = useState(false);
  const [reject, setReject] = useState(false);

  const { data: submission } = useGetAdminSubmissionDetails(
    product.submissionId
  );

  const shouldHideButtons =
    product?.status === 'active' ||
    product?.status === 'rejected' ||
    product?.status === 'approved';

  const submissionDetails = {
    id: product.submissionId.toString(),
    name: submission.name,
    publisherUsername: submission.publisher.user.username,
    createdAt: submission.createdAt,
  };

  return (
    <>
      {approve && (
        <ApproveProductSubmissionsModal
          isModalOpen={approve}
          submission={submissionDetails}
          closeModal={() => setApprove(false)}
        />
      )}
      {reject && (
        <RejectModal
          isModalOpen={!!reject}
          submission={submissionDetails}
          closeModal={() => setReject(false)}
        />
      )}
      <NavigationActions
        onBack={() => setProductToApprove(undefined)}
        backLink={ADMIN}
        primaryText="Approve"
        secondaryText="Reject"
        onPrimaryClick={() => setApprove(true)}
        onSecondaryClick={() => setReject(true)}
        backText="Back to the Product submissions"
        hideButtons={shouldHideButtons}
      />
    </>
  );
};

export default SubmissionNavActions;
