import { useState } from 'react';

import {
  IChangeVisibilityParams,
  IProduct,
  UserGetProductsDetailData,
} from '@interfaces';

import LabelValueGrid from '@components/LabelValueGrid';
import ReasonItem from '@components/ReasonItem';
import Button, { BUTTON_VARIANT } from '@components/button';
import Modal from '@components/modals/Modal';

import { EyeOpenLargeIcon } from '@shared/assets';
import { REASON_OPTIONS } from '@shared/constants';
import { ProductVisibility } from '@shared/enums/products';

import { getVisibilityModalGridData } from './getVisibilityModalGridData';

interface AdminProductVisibilityModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentVisibility: ProductVisibility;
  onConfirm: (data: Partial<IChangeVisibilityParams>) => void;
  product: UserGetProductsDetailData | IProduct;
  isLoading: boolean;
}

const AdminProductVisibilityModal = ({
  isOpen,
  onClose,
  currentVisibility,
  onConfirm,
  product,
  isLoading,
}: AdminProductVisibilityModalProps) => {
  const isPublic = currentVisibility === ProductVisibility.PUBLIC;

  const [rejectReason, setRejectedReason] = useState(REASON_OPTIONS[0].value);
  const [rejectDescription, setRejectDescription] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const modalGridData = getVisibilityModalGridData(product);

  const handleTextChange = (value: string) => {
    setRejectDescription(value);

    if (errorMessage) setErrorMessage('');
  };

  return (
    <Modal
      isOpen={isOpen}
      disableClose={isLoading}
      setIsOpen={onClose}
      className="!max-w-25.5rem p-6"
      modalTitle={isPublic ? 'Disable visibility' : 'Enable visibility'}
      modalDescription={
        isPublic
          ? 'Are you sure want to disable visibility of this product on the marketplace? Existing users will continue using the product.'
          : 'Are you sure you want to enable visibility for this product on the marketplace'
      }
      icon={!isPublic && <EyeOpenLargeIcon className="text-green500" />}
    >
      <div className="flex flex-col gap-6">
        <div>
          {isPublic ? <h1 className="font-large mb-4">Product</h1> : null}
          <LabelValueGrid labelValuePairs={modalGridData} />
        </div>

        {isPublic ? (
          <div>
            <h1 className="font-large mb-4">
              Select reason for rejection of the product:
            </h1>
            {REASON_OPTIONS.map((option) => (
              <ReasonItem
                isSelected={rejectReason === option.value}
                option={option}
                key={option.value}
                setSelectedItem={() => setRejectedReason(option.value)}
                text={rejectDescription}
                setText={handleTextChange}
                errorMessage={errorMessage}
              />
            ))}
          </div>
        ) : null}

        <hr className="border-neutral700" />

        <div className="flex justify-between gap-4">
          <Button
            onClick={onClose}
            className="rounded-bl-tr border border-neutral600 bg-transparent px-4 py-2 text-white"
            variant={BUTTON_VARIANT.SECONDARY}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              if (rejectReason === 'other' && !rejectDescription.length) {
                return setErrorMessage('Please provide your other reason');
              }

              onConfirm({
                rejectReason,
                rejectDescription,
              });
            }}
            className="bg-green500 px-4 py-2 text-neutral900"
            isLoading={isLoading}
            disabled={isPublic && !rejectReason}
          >
            {isPublic ? 'Disable product' : 'Enable'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AdminProductVisibilityModal;
