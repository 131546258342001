import { twMerge } from 'tailwind-merge';

import { RemoveIcon } from '@shared/assets';

interface IProps {
  onClick: () => void;
  className?: string;
  iconClassName?: string;
}

const RemoveBoxedIcon = ({ onClick, className, iconClassName }: IProps) => {
  return (
    <button className={twMerge('nav-bar-icon', className)} onClick={onClick}>
      <RemoveIcon className={iconClassName} />
    </button>
  );
};

export default RemoveBoxedIcon;
