import { twMerge } from 'tailwind-merge';

const TextareaStyle = (
  error?: string,
  isReadOnly?: boolean,
  textAreaClassName?: string
) =>
  twMerge(
    'font-xsmall green-scrollbar w-full resize-none appearance-none border border-neutral700 bg-neutral700 px-1.5 py-1 font-bold text-white',
    'focus:outline-none focus-visible:outline-none',
    'disabled:cursor-not-allowed disabled:bg-transparent',
    !isReadOnly && 'focus:bg-green900',
    isReadOnly && error && 'bg-red900',
    textAreaClassName
  );

export default TextareaStyle;
