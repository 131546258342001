import LabelValueGrid from '@components/LabelValueGrid';
import Modal from '@components/modals/Modal';

import { formatDate } from '@features/utils';

import ReasonSelect from './ReasonSelect';

interface ISubmissionDetails {
  id: string;
  name: string;
  publisherUsername: string;
  createdAt: string;
}

interface IProps {
  isModalOpen: boolean;
  closeModal: () => void;
  submission: ISubmissionDetails;
}

const RejectModal = ({ isModalOpen, closeModal, submission }: IProps) => {
  if (isModalOpen)
    window.onbeforeunload = () => 'Changes that you made may not be saved.';

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={closeModal}
      className="!max-w-25.5rem"
      modalTitle="Complete rejection"
    >
      <div className="flex flex-col gap-6">
        <div>
          <p className="font-large mb-2 text-neutral200">Product </p>
          <LabelValueGrid
            labelValuePairs={[
              {
                label: 'Product:',
                value: submission.name,
              },
              {
                label: 'Publisher:',
                value: submission.publisherUsername,
              },
              {
                label: 'Date submitted:',
                value: formatDate(submission.createdAt),
              },
            ]}
          />
        </div>
        <hr className="border-neutral700" />
        <div>
          <p className="font-large mb-4 text-neutral200">
            Select reason for rejection of the product:
          </p>
          <ReasonSelect closeModal={closeModal} submissionId={submission.id} />
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
