import { twMerge } from 'tailwind-merge';

import { IPopoverItemProps } from './types';

const PopoverItem = ({
  icon: Icon,
  text,
  onClick,
  iconClassName,
  textClassName,
  isDisabled,
}: IPopoverItemProps) => {
  return (
    <button
      className="group/popoverItem flex w-full items-center gap-1.5 p-2.5 text-neutral300 transition-all disabled:opacity-30 hover:bg-neutral700"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        onClick();
      }}
      disabled={isDisabled}
    >
      {Icon && (
        <Icon
          className={twMerge(
            'size-4 transition-all group-hover/popoverItem:text-white',
            iconClassName
          )}
        />
      )}
      <p
        className={twMerge(
          'label-medium !leading-none transition-all group-hover/popoverItem:text-white',
          textClassName
        )}
      >
        {text}
      </p>
    </button>
  );
};

export default PopoverItem;
