'use client';

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import {
  IChooseServiceModelResponse,
  IProduct,
  UserGetProductsDetailData,
} from '@interfaces';
import { IProductWithSubmissionId } from '@interfaces/admin/submissions';

interface ProductActionsContext {
  productToApprove?: IProductWithSubmissionId;
  setProductToApprove: Dispatch<
    SetStateAction<IProductWithSubmissionId | undefined>
  >;
  productToSetVisibility?: UserGetProductsDetailData | IProduct;
  setProductToSetVisibility: Dispatch<
    SetStateAction<UserGetProductsDetailData | IProduct | undefined>
  >;
  preDeployModel?: IChooseServiceModelResponse;
  setPreDeployModel: Dispatch<
    SetStateAction<IChooseServiceModelResponse | undefined>
  >;
}

const ProductActionsContext = createContext<ProductActionsContext | null>(null);

interface IProductSubmissionProviderProps {
  children: ReactNode;
}

export function ProductSubmissionProvider({
  children,
}: IProductSubmissionProviderProps) {
  const [productToApprove, setProductToApprove] =
    useState<IProductWithSubmissionId>();
  const [productToSetVisibility, setProductToSetVisibility] = useState<
    UserGetProductsDetailData | IProduct
  >();
  const [preDeployModel, setPreDeployModel] =
  useState<IChooseServiceModelResponse>();

  return (
    <ProductActionsContext.Provider
      value={{
        productToApprove,
        setProductToApprove,
        productToSetVisibility,
        setProductToSetVisibility,
        preDeployModel,
        setPreDeployModel
      }}
    >
      {children}
    </ProductActionsContext.Provider>
  );
}

export function useProductActionsContext() {
  const context = useContext(ProductActionsContext);
  if (context === null) {
    throw new Error(
      'useProductActionsContext must be used within a ProductSubmissionProvider'
    );
  }

  return context;
}

export default ProductSubmissionProvider;
