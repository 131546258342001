import { IChangeVisibilityParams } from '@interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ADMIN_PRODUCTS } from '@shared/constants/queryKeys';

import { marketplacesApi } from '../marketplacesApi';

const useChangeVisibility = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: IChangeVisibilityParams) =>
      marketplacesApi.client.changeVisibility(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_PRODUCTS] });
      onSuccess && onSuccess();
    },
  });
};

export default useChangeVisibility;
