import React from 'react';

import {
  DocIcon,
  FileIcon,
  PPtIcon,
  PdfIcon,
  XlsIcon,
  ZipIcon,
} from '@shared/assets';

export enum CONTENT_TYPE {
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  X_ZIP = 'application/x-zip',
  X_ZIP_COMPRESSED = 'application/x-zip-compressed',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  MP4 = 'video/mp4',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

export const TYPE_NAMES = {
  [CONTENT_TYPE.DOC]: 'doc',
  [CONTENT_TYPE.DOCX]: 'doc',
  [CONTENT_TYPE.XLS]: 'xls',
  [CONTENT_TYPE.XLSX]: 'xlsx',
  [CONTENT_TYPE.PDF]: 'pdf',
  [CONTENT_TYPE.ZIP]: 'zip',
  [CONTENT_TYPE.X_ZIP]: ' ',
  [CONTENT_TYPE.X_ZIP_COMPRESSED]: ' ',
  [CONTENT_TYPE.PPT]: 'ppt',
  [CONTENT_TYPE.PPTX]: 'pptx',
  [CONTENT_TYPE.MP4]: 'mp4',
  [CONTENT_TYPE.PNG]: 'png',
  [CONTENT_TYPE.JPEG]: 'jpeg',
  [CONTENT_TYPE.JPG]: 'jpg',
};

export const FILE_ICONS = {
  [CONTENT_TYPE.DOC]: <DocIcon />,
  [CONTENT_TYPE.DOCX]: <DocIcon />,
  [CONTENT_TYPE.XLS]: <XlsIcon />,
  [CONTENT_TYPE.XLSX]: <XlsIcon />,
  [CONTENT_TYPE.PDF]: <PdfIcon />,
  [CONTENT_TYPE.ZIP]: <ZipIcon />,
  [CONTENT_TYPE.X_ZIP]: <ZipIcon />,
  [CONTENT_TYPE.X_ZIP_COMPRESSED]: <ZipIcon />,
  [CONTENT_TYPE.PPT]: <PPtIcon />,
  [CONTENT_TYPE.PPTX]: <PPtIcon />,
  [CONTENT_TYPE.MP4]: <FileIcon />,
  [CONTENT_TYPE.JPEG]: null,
  [CONTENT_TYPE.PNG]: null,
  [CONTENT_TYPE.JPG]: null,
};

export const FILES_SIZES = {
  '20mb': 20_000_000,
  '10mb': 10_000_000,
  '100mb': 100_000_000,
};

export const SUPPORTED_FILE_DOCS_FORMATS = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/zip',
];

export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png', 'image/jpg'];

export const FILE_API_FORMATS = {
  PDF: { 'application/pdf': [] },
  ZIP: { 'application/zip': [] },
  X_ZIP_COMPRESSED: { 'application/x-zip-compressed': [] },
  X_ZIP: { 'application/x-zip': [] },
  DOCS: {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      [],
  },
  JPG: { 'image/jpg': [] },
  JPEG: { 'image/jpeg': [] },
  PNG: { 'image/png': [] },
  MP4: { 'video/mp4': [] },
};

export const SUPPORTED_FILE_API_IMAGE_FORMATS = {
  ...FILE_API_FORMATS.JPG,
  ...FILE_API_FORMATS.JPEG,
  ...FILE_API_FORMATS.PNG,
};

export const SUPPORTED_FILE_API_FILE_DOCS_FORMATS = {
  ...FILE_API_FORMATS.PDF,
  ...FILE_API_FORMATS.ZIP,
  ...FILE_API_FORMATS.X_ZIP_COMPRESSED,
  ...FILE_API_FORMATS.X_ZIP,
  ...FILE_API_FORMATS.DOCS,
};
