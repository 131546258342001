import { IFormError } from '@interfaces';

export const getErrors = (errors?: string | IFormError | IFormError[]) => {
  let parsedErrors: IFormError[] = [];

  if (errors) {
    if (typeof errors === 'string') {
      parsedErrors = [{ message: errors }];
    } else {
      parsedErrors = Array.isArray(errors) ? errors : [errors];
    }
  }

  const formattedErrors = parsedErrors.reduce(
    (acc: string[], err: IFormError) => {
      if (err?.message) {
        acc.push(err.message.trim());
      }
      return acc;
    },
    []
  );

  return {
    parsedErrors: formattedErrors,
    hasErrors: formattedErrors.length > 0,
    formattedErrorMessages: formattedErrors,
  };
};
