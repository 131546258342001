import { twMerge } from 'tailwind-merge';

import { LogoHeadSvg, LogoSvg } from '@shared/assets';

const LogoIcon = ({ isOnlyFullLogo }: { isOnlyFullLogo?: boolean }) => {
  return (
    <>
      {!isOnlyFullLogo && (
        <LogoHeadSvg className="size-10 overflow-visible lg:hidden" />
      )}
      <LogoSvg
        width={160}
        height={40}
        className={twMerge(
          'size-full overflow-visible',
          isOnlyFullLogo ? 'block' : 'hidden lg:block'
        )}
      />
    </>
  );
};

export default LogoIcon;
