import { RejectForm } from '@interfaces/admin/submissions';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ADMIN_SUBMISSIONS } from '@shared/constants/queryKeys';
import { toastSuccess } from '@shared/utils';

import { productSubmissionsApi } from '../productSubmissionsApi';

const useRejectSubmission = (id: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: RejectForm) =>
      productSubmissionsApi.client.rejectSubmission(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SUBMISSIONS] });
      toastSuccess('This version has been successfully rejected');
      onSuccess && onSuccess();
    },
  });
};

export default useRejectSubmission;
