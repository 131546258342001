import { twMerge } from 'tailwind-merge';

interface IProps {
  labelValuePairs: {
    label: string;
    value: string | number | React.ReactNode;
  }[];
}

const LabelValueGrid = ({ labelValuePairs }: IProps) => {
  return (
    <div className="border border-neutral600">
      {labelValuePairs.map(({ label, value }, idx) => (
        <div
          className={twMerge(
            'flex justify-between border-neutral600 p-2 font-medium text-neutral200',
            idx < labelValuePairs.length - 1 && 'border-b'
          )}
          key={label}
        >
          <p>{label}</p>
          <div>{value}</div>
        </div>
      ))}
    </div>
  );
};

export default LabelValueGrid;
