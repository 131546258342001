import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
}

const PopoverContainer = ({ children, isOpen, className }: IProps) => {
  return (
    <div
      className={twMerge(
        'roundedBlTr absolute left-0 top-10 z-50 size-max min-w-40 border border-neutral700 bg-neutral900 py-1 transition-all',
        isOpen ? 'opacity-100' : 'pointer-events-none opacity-0',
        className
      )}
    >
      {children}
    </div>
  );
};

export default PopoverContainer;
