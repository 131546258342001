import { twMerge } from 'tailwind-merge';

interface IProps {
  className?: string;
}

const DividerFullWidth = ({ className }: IProps) => {
  return (
    <div
      className={twMerge(
        'relative -mt-px h-px w-full bg-neutral700',
        className
      )}
    />
  );
};

export default DividerFullWidth;
