import { PaginatedResponseWrapper, ResponseWrapper } from '@interfaces';
import { ISubmission, RejectForm } from '@interfaces/admin/submissions';
import { IFetchParams } from '@interfaces/queryParams';

import { withAuthenticatedAdminClientRequest } from '@api/requestBuilder/client/withAuthenticatedAdminClientRequest';
import {
  appendParamsToUrl,
  formatDateParams,
} from '@api/requestBuilder/helpers';
import { withAuthenticatedAdminServerRequest } from '@api/requestBuilder/server/withAuthenticatedAdminServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { adminProductSubmissionsRoutes } from '../adminApi.routes';

const getStatus = (inReview: boolean, params?: IFetchParams) => {
  if (params?.submissionStatuses) {
    return params.submissionStatuses;
  }
  if (inReview) {
    return ['in_review'];
  }
  return ['approved', 'rejected'];
};

const getSubmissions =
  (request: ICallableRequestBuilder<PaginatedResponseWrapper<ISubmission[]>>) =>
  async (params?: IFetchParams, inReview = true, timeZone?: string) => {
    const formattedParams = formatDateParams(params, timeZone);
    const url = appendParamsToUrl(
      adminProductSubmissionsRoutes.getSubmissions,
      {
        ...formattedParams,
        submissionStatuses: getStatus(inReview, params),
      }
    );
    return await request.call(url);
  };

const getAdminSubmissionDetails =
  (request: ICallableRequestBuilder<ResponseWrapper<ISubmission>>) =>
  async (id: number) => {
    const url = adminProductSubmissionsRoutes.getAdminSubmissionDetails(id);
    return request.call(url);
  };

const approveSubmission =
  (request: ICallableRequestBuilder<ResponseWrapper<null>>) =>
  async (id: string) => {
    const url = adminProductSubmissionsRoutes.approveSubmission(id);
    return await request.call(url, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
    }));
  };

const rejectSubmission =
  (request: ICallableRequestBuilder<ResponseWrapper<null>>) =>
  async (id: string, data: RejectForm) => {
    const url = adminProductSubmissionsRoutes.rejectSubmission(id);
    return await request.call(url, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }));
  };

export const productSubmissionsApi = {
  client: {
    getSubmissions: withAuthenticatedAdminClientRequest(getSubmissions),
    getAdminSubmissionDetails: withAuthenticatedAdminClientRequest(
      getAdminSubmissionDetails
    ),
    approveSubmission: withAuthenticatedAdminClientRequest(approveSubmission),
    rejectSubmission: withAuthenticatedAdminClientRequest(rejectSubmission),
  },
  server: {
    getSubmissions: withAuthenticatedAdminServerRequest(getSubmissions),
    getAdminSubmissionDetails: withAuthenticatedAdminServerRequest(
      getAdminSubmissionDetails
    ),
  },
};
