import { forwardRef } from 'react';

import { IForumRejectOption } from '@interfaces';
import { IAdminRejectOption } from '@interfaces/admin';
import { twMerge } from 'tailwind-merge';

import InputWrapper from '@components/form/inputWrapper';
import TextareaStyle from '@components/form/styles/textareaStyle';

import { RadioCheckedIcon, RadioIcon } from '@shared/assets';
import { MAX_TEXTAREA_LENGTH } from '@shared/constants';
import { E_REASON_OPTIONS } from '@shared/enums';

interface ReasonItemProps {
  isSelected: boolean;
  option: IAdminRejectOption | IForumRejectOption;
  text: string;
  setText: (value: string) => void;
  setSelectedItem: () => void;
  errorMessage?: string;
}

const ReasonItem = forwardRef<HTMLTextAreaElement, ReasonItemProps>(
  (
    { isSelected, option, text, setText, setSelectedItem, errorMessage },
    ref
  ) => {
    return (
      <button
        onClick={setSelectedItem}
        onKeyUp={(e) => {
          e.preventDefault(); // Prevents pressing space from closing the menu
        }}
        className={twMerge(
          'align-self-stretch font-large mb-2 flex w-full cursor-pointer flex-col items-start gap-4 text-wrap border p-3 text-left text-neutral200',
          isSelected ? 'border-green600' : 'border-neutral700 opacity-50'
        )}
      >
        <div className="flex items-center justify-start gap-2">
          {isSelected ? (
            <RadioCheckedIcon className="shrink-0" />
          ) : (
            <RadioIcon className="shrink-0" />
          )}
          {option.label}
        </div>

        {isSelected && option.value === E_REASON_OPTIONS.OTHER && (
          <div
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation(); // Prevents closing the menu when clicking on the textarea
            }}
            className="w-full"
          >
            <InputWrapper
              className="relative bg-neutral900"
              label="Reject Reason"
              hideIsOptional
              error={errorMessage}
            >
              <textarea
                ref={ref}
                className={TextareaStyle()}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                value={text}
                maxLength={MAX_TEXTAREA_LENGTH}
              />
            </InputWrapper>
          </div>
        )}
      </button>
    );
  }
);

ReasonItem.displayName = 'ReasonItem';

export default ReasonItem;
