'use client';

import { useRouter } from 'next/navigation';

import Button, { BUTTON_VARIANT } from '@components/button';

import { ChevronLeft } from '@shared/assets';

interface IProps {
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  primaryText: string;
  secondaryText: string;
  backText: string;
  isSecondaryDisabled?: boolean;
  isPrimaryDisabled?: boolean;
  onBack?: () => void;
  backLink?: string;
  hideButtons?: boolean;
}

const NavigationActions = ({
  onPrimaryClick,
  onSecondaryClick,
  primaryText,
  secondaryText,
  backText,
  isPrimaryDisabled,
  isSecondaryDisabled,
  onBack,
  backLink,
  hideButtons,
}: IProps) => {
  const router = useRouter();

  return (
    <div className="align-center flex flex-1 justify-between border-l-neutral700">
      <button
        className="ml-4 flex items-center gap-2 text-white"
        onClick={() => {
          onBack && onBack();
          backLink ? router.push(backLink) : router.back();
        }}
      >
        <ChevronLeft />
        <span className="hidden sm:inline">{backText}</span>
      </button>
      {!hideButtons && (
        <div className="flex gap-2">
          <Button
            className="max-sm:gap-2 max-sm:rounded-sm max-sm:px-2 max-sm:py-1"
            onClick={onPrimaryClick}
            variant={BUTTON_VARIANT.PRIMARY}
            disabled={isPrimaryDisabled}
          >
            {primaryText}
          </Button>
          <Button
            className="max-sm:gap-2 max-sm:rounded-sm max-sm:px-2 max-sm:py-1"
            disabled={isSecondaryDisabled}
            onClick={onSecondaryClick}
            variant={BUTTON_VARIANT.SECONDARY}
          >
            {secondaryText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NavigationActions;
