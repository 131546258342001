import Link from 'next/link';
import { MouseEvent, ReactNode, useState } from 'react';

import { IFormError } from '@interfaces';
import { twMerge } from 'tailwind-merge';

import { EditPencilIcon, ErrorIcon, InformationIcon } from '@shared/assets';

import { getErrors } from './utils';

interface IProps {
  id?: string;
  label?: string | ReactNode;
  children: ReactNode;
  inline?: boolean;
  required?: boolean;
  error?: string | IFormError | IFormError[];
  className?: string;
  errorClassName?: string;
  buttonText?: string;
  linkText?: string;
  linkHref?: string;
  splitLine?: boolean;
  onButtonClick?: () => void;
  containerProps?: Record<string, unknown>;
  tooltip?: string;
  isEdited?: boolean;
  hideIsOptional?: boolean;
  labelClassName?: string;
  tooltipClassName?: string;
  optionalDescriptionElement?: ReactNode;
}

const InputWrapper = ({
  id,
  label,
  children,
  inline,
  required,
  className,
  errorClassName,
  error,
  buttonText,
  linkText,
  linkHref,
  containerProps,
  splitLine,
  hideIsOptional,
  onButtonClick,
  tooltip,
  isEdited,
  labelClassName,
  tooltipClassName,
  optionalDescriptionElement,
}: IProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onButtonClick?.();
  };

  const shouldHideButtons = !label && !buttonText && !linkText;

  const { formattedErrorMessages, hasErrors } = getErrors(error);

  return (
    <div
      className={twMerge(
        'flex w-full flex-col border border-neutral700 px-2.5 py-3 transition-all duration-300 ease-out focus-within:border focus-within:border-neutral500',
        inline && 'align-center flex-row gap-2',
        hasErrors && 'border-red500 focus-within:border-red500',
        className
      )}
      {...containerProps}
    >
      {!shouldHideButtons && (
        <div
          className={twMerge(
            (buttonText || linkText) &&
              'flex w-full items-center justify-between',
            !inline && 'mb-2 flex'
          )}
        >
          <div className="flex">
            {label && (
              <div className="flex flex-col">
                <label
                  htmlFor={id}
                  className={twMerge(
                    'font-xsmall mr-1 flex items-center font-bold uppercase text-neutral200',
                    labelClassName
                  )}
                >
                  {label}
                  {required ? (
                    <span className="ml-1 text-red500">*</span>
                  ) : (
                    !hideIsOptional && (
                      <span className="ml-1 text-neutral400">(optional)</span>
                    )
                  )}
                  {!!optionalDescriptionElement && optionalDescriptionElement}
                </label>
              </div>
            )}
            {tooltip && (
              <div className="relative flex size-max">
                <button
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <InformationIcon className="size-3.5 text-neutral400" />
                </button>
                <div
                  className={twMerge(
                    'absolute bottom-6 left-1/2 -z-10 max-w-[250px] -translate-x-1/2 transform rounded-lg bg-green500 p-4 opacity-0 transition-all sm:w-max sm:max-w-96',
                    isHovered && 'bottom-8 z-10 opacity-100',
                    tooltipClassName
                  )}
                >
                  <p className="min-w-40 whitespace-normal break-words font-medium text-black sm:w-auto">
                    {tooltip}
                  </p>
                </div>
              </div>
            )}
            {isEdited && (
              <EditPencilIcon className="size-3.5 text-neutral400" />
            )}
          </div>
          <div className="flex items-center">
            {!!buttonText && (
              <button
                className="font-xsmall font-bold uppercase text-yellow500"
                onClick={handleButtonClick}
              >
                {buttonText}
              </button>
            )}
            {!!linkText && !!linkHref && (
              <Link
                className="font-xsmall font-bold uppercase text-yellow500"
                href={linkHref}
              >
                {linkText}
              </Link>
            )}
          </div>
        </div>
      )}
      {splitLine && <hr className="mb-6 mt-1 w-full text-neutral700" />}
      {children}

      {formattedErrorMessages?.map((singleError, idx) => (
        <div
          className={twMerge(
            'flex h-0 items-center gap-1 text-red500 opacity-0 transition-all duration-200 ease-in-out',
            singleError && 'mt-2 h-4 opacity-100',
            errorClassName
          )}
          key={`Error - ${idx}`}
        >
          <ErrorIcon className="h-3 w-3.5" />
          <p className="font-xsmall">{singleError}</p>
        </div>
      ))}
    </div>
  );
};

export default InputWrapper;
