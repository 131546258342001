'use client';

import { useState } from 'react';

import {
  IChangeVisibilityParams,
  IProduct,
  UserGetProductsDetailData,
} from '@interfaces';

import useChangeVisibility from '@api/admin/marketplaces/mutations/useChangeVisibility';

import NavigationActions from '@components/NavigationActions/NavigationActions';

import { useProductActionsContext } from '@shared/contexts/ProductActionsContext';
import { ProductVisibility } from '@shared/enums/products';
import { PRODUCT_MANAGEMENT, toastSuccess } from '@shared/utils';

import AdminProductVisibilityModal from '../AdminProductVisibilityModal';

const ProductNavActions = ({
  product,
}: {
  product: UserGetProductsDetailData | IProduct;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setProductToSetVisibility } = useProductActionsContext();
  const { mutateAsync, isPending } = useChangeVisibility(() => {
    setIsOpen(false);
    setProductToSetVisibility(product);
    toastSuccess(
      `Product visibility successfully  ${product?.visibility?.value === ProductVisibility.PUBLIC ? 'disabled' : 'enabled'}.`
    );
  });

  const handleVisibilityChange = async (
    data: Partial<IChangeVisibilityParams>
  ) => {
    const newVisibility =
      product?.visibility?.value === ProductVisibility.PUBLIC
        ? ProductVisibility.PRIVATE
        : ProductVisibility.PUBLIC;
    product &&
      (await mutateAsync({
        ...data,
        visibility: newVisibility,
        productId: product.id,
      }));
  };

  return (
    <>
      <NavigationActions
        primaryText="Enable Visibility"
        secondaryText="Disable"
        onBack={() => setProductToSetVisibility(undefined)}
        onPrimaryClick={() => setIsOpen(true)}
        onSecondaryClick={() => setIsOpen(true)}
        isPrimaryDisabled={
          product.visibility?.value === ProductVisibility.PUBLIC
        }
        isSecondaryDisabled={
          product.visibility?.value === ProductVisibility.PRIVATE
        }
        backText="Back to the Product management"
        backLink={PRODUCT_MANAGEMENT}
      />
      {isOpen && (
        <AdminProductVisibilityModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          currentVisibility={
            product.visibility?.value || ProductVisibility.PUBLIC
          }
          onConfirm={handleVisibilityChange}
          product={product}
          isLoading={isPending}
        />
      )}
    </>
  );
};

export default ProductNavActions;
