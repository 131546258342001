import { ICallableRequestBuilder } from '../types';
import { ServerRequestBuilder } from './serverRequestBuilder';

export const withAuthenticatedAdminServerRequest = <TArgs extends unknown[], T>(
  callback: (
    request: ICallableRequestBuilder<T>
  ) => (...args: TArgs) => Promise<T>
): ((...args: TArgs) => Promise<T>) => {
  return async (...args: TArgs) => {
    const request = new ServerRequestBuilder<T>();
    await request.authenticateAdmin();
    return callback(request)(...args);
  };
};
