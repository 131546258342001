import { twMerge } from 'tailwind-merge';

const InputStyle = (error?: string, isReadOnly?: boolean, type?: string) =>
  twMerge(
    'font-xsmall transitionColor min-w-[26px] resize-none appearance-none rounded-none bg-neutral700 px-1.5 py-1 font-bold text-white',
    'focus:outline-none focus-visible:outline-none',
    !isReadOnly && 'focus:bg-green900',
    'disabled:cursor-not-allowed',
    'disabled:bg-neutral700',
    error && 'bg-red900 focus:bg-red900 active:bg-red900',
    type === 'number' && 'hide-input-arrows'
  );

export default InputStyle;
