import Link from 'next/link';

import { twMerge } from 'tailwind-merge';

import LogoIcon from './LogoIcon';

interface IProps {
  isOnlyFullLogo?: boolean;
  className?: string;
  href?: string;
  isHomepage?: boolean;
}

const Logo = ({
  isOnlyFullLogo,
  className,
  href = '/',
  isHomepage,
}: IProps) => {
  const logoClassName = twMerge(
    'flexCenter ml-3 w-10 shrink-0 text-neutral300 lg:w-40 xl:ml-0 xl:h-10',
    className
  );

  return isHomepage ? (
    <a className={logoClassName} href={href}>
      <LogoIcon isOnlyFullLogo={isOnlyFullLogo} />
    </a>
  ) : (
    <Link href={href} className={logoClassName}>
      <LogoIcon isOnlyFullLogo={isOnlyFullLogo} />
    </Link>
  );
};

export default Logo;
