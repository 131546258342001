import useApproveSubmission from '@api/admin/product-submissions/mutations/useApproveSubmission';

import LabelValueGrid from '@components/LabelValueGrid';
import Button, { BUTTON_VARIANT } from '@components/button';
import Modal from '@components/modals/Modal';

import { formatDate } from '@features/utils';

import { CheckCircle } from '@shared/assets';
import { useProductActionsContext } from '@shared/contexts/ProductActionsContext';

interface ISubmissionDetails {
  id: string;
  name: string;
  publisherUsername: string;
  createdAt: string;
}

interface IProps {
  isModalOpen: boolean;
  closeModal: () => void;
  submission: ISubmissionDetails;
}
const ApproveProductSubmissionsModal = ({
  isModalOpen,
  closeModal,
  submission,
}: IProps) => {
  const { setProductToApprove } = useProductActionsContext();
  const { mutateAsync, isPending } = useApproveSubmission(submission.id, () => {
    closeModal();
    setProductToApprove(undefined);
  });

  return (
    <Modal isOpen={isModalOpen} setIsOpen={closeModal} className="!w-25.5rem">
      <div className="flex flex-col gap-6">
        <div className="flex h-20 w-20 items-center justify-center gap-2.5 rounded-bl-sm rounded-br-sm rounded-tr-sm border border-neutral700 bg-neutral900">
          <CheckCircle className="size-8" />
        </div>
        <div>
          <p className="font-xxlarge font-bold text-white">Approve product</p>
          <div>
            <p className="font-large text-neutral200">
              Are you sure you want to approve this product?
            </p>
          </div>
        </div>
        <div>
          <LabelValueGrid
            labelValuePairs={[
              {
                label: 'Product:',
                value: submission.name,
              },
              {
                label: 'Publisher:',
                value: submission.publisherUsername,
              },
              {
                label: 'Date submitted:',
                value: formatDate(submission.createdAt),
              },
            ]}
          />
        </div>
        <hr className="border-neutral700" />
        <div className="flex justify-between">
          <Button
            onClick={closeModal}
            variant={BUTTON_VARIANT.SECONDARY}
            className="w-fit"
          >
            Cancel
          </Button>
          <Button
            onClick={() => mutateAsync()}
            disabled={isPending}
            className="w-fit"
          >
            Approve product
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveProductSubmissionsModal;
